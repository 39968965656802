<template>
  <view-item title="收款明细">
    <template #operation>
      <en-button type="primary" @click="operation.export.click">导出</en-button>
    </template>
    <en-card body-class="h-full" class="h-full">
      <flex-box>
        <template #default="{ height }">
          <en-tabs v-model="tabs.active">
            <en-tab-pane label="工单收款明细" name="order" :style="{ height: height - 55 + 'px', overflow: 'auto' }">
              <form-query :model="order.form.data" :method="order.get">
                <en-form-item label="收款搜索">
                  <en-input v-model="order.form.data.serviceReceiptQuickSearch"  placeholder="车主/车牌/工单号/收款单号"></en-input>
                </en-form-item>
                <en-form-item label="客户来源">
                  <select-maintain
                    v-model="order.form.data.salesmanType"
                    :ajax="{ action: 'GET /enocloud/common/lookup/:lookupType', params: (params) => (params.paths = ['CLKTYPE']) }"
                    :props="{ label: 'message', value: 'code' }"
                    class="w-full"
                  ></select-maintain>
                </en-form-item>
                <en-form-item label="介绍人">
                  <select-maintain
                    v-model="order.form.data.salesmanId"
                    :ajax="{
                      action: 'GET /enocloud/common/clerk',
                      params: (params, value) => (params.payload = { code: order.form.data.salesmanType, name: value })
                    }"
                    :disabled="order.form.data.salesmanType === '' || order.form.data.salesmanType === 'O'"
                    :props="{ label: 'name', value: 'id' }"
                    value-key="id"
                    lazy
                    remote
                    class="w-full"
                  ></select-maintain>
                </en-form-item>
              </form-query>
              <flex-box>
                <template #default="{ height }">
                  <table-dynamic
                    code="SVCQRCT"
                    :height="height"
                    :data="order.data"
                    :method="order.get"
                    :loading="order.loading"
                    pagination
                    :paging="order.paging"
                    :config="order.config"
                    show-summary
                    :summary-method="order.summaryMethod"
                  >
                    <template #SETTLEMENT_DATETIME="{ row }: { row: EnocloudQueryDefinitions['ServiceQueryDto'] }">
                      {{ formatDate(row.settlementDatetime,true) }}
                    </template>
                    <template #IS_INVOICE="{ row }: { row: EnocloudQueryDefinitions['ServiceQueryDto'] }">
                      {{ row.invoiced.code === 'N' ? '未开票' : '已开票' }}
                    </template>
                  </table-dynamic>
                </template>
              </flex-box>
            </en-tab-pane>
            <en-tab-pane label="销售收款明细" name="sale" :style="{ height: height - 55 + 'px', overflow: 'auto' }">
              <flex-box>
                <template #default="{ height }">
                  <table-dynamic
                    code="SALRCT"
                    :height="height"
                    :data="sale.data"
                    :method="sale.get"
                    :loading="sale.loading"
                    pagination
                    :paging="sale.paging"
                    :config="sale.config"
                    show-summary
                    :summary-method="sale.summaryMethod"
                  >
                    <template #RECEIPT_DATETIME="{ row }: { row: EnocloudQueryDefinitions['ServiceQueryDto'] }">
                      {{ formatDate(row?.receiptDatetime,true) }}
                    </template></table-dynamic
                  >
                </template>
              </flex-box>
            </en-tab-pane>
          </en-tabs>
        </template>
      </flex-box>
    </en-card>
  </view-item>
</template>

<script lang="ts">
import { calculator } from '@enocloud/utils'
import { type TableColumnCtx } from '@enocloud/components'
export default factory({
  config: {
    children: {
      operation: {
        children: {
          export: {
            ajax: {
              get: {
                action: 'GET /enocloud/query/service/receipt/export'
              },
              buyget: {
                action: 'GET /enocloud/sale/receipt/export',
                params(params) {
                  params.payload = this.order.$ajaxParams.payload
                }
              }
            },
            async click() {
              switch (this.tabs.active) {
                case 'order':
                  this.operation.export.get()
                  this.store.openDownload()
                  break
                case 'sale':
                  this.operation.export.buyget()
                  this.store.openDownload()
                  break
              }
            }
          }
        }
      },
      tabs: {
        active: 'order'
      },
      order: {
        form: {
          data: {
            salesmanType: '',
            salesmanId: '',
            salesmanName: '',
            branchIds: [],
            serviceReceiptQuickSearch: ''
          }
        },

        ajax: {
          get: {
            action: 'GET /enocloud/query/service/receipt',
            summary: 'GET /enocloud/query/service/receipt/summary',
            data: 'array',
            loading: true,
            pagination: true,
            params(params) {
              params.payload = this.order.form.data
            }
          }
        },
        summaryMethod<T extends EnocloudServiceDefinitions['ServiceMaintenanceDto'], K extends keyof T>(ctx: {
          columns: TableColumnCtx<T>[]
          data: T[]
        }) {
          return ctx.columns
            .map((item) => item.property)
            .map((key, index) => {
              if (['receivedAmount', 'receivableBadDebt'].includes(key)) {
                return `${ctx.data.reduce((total, d) => calculator.add(total, d[key as K] as number), 0)}`
              } else return !index ? '合计' : '-'
            })
        },
        config: {
          SERVICE_CATEGORY: {
            header: {
              filter: {
                type: 'select',
                field: 'serviceCategoryId',
                props: {
                  ajax: { action: 'GET /enocloud/common/service/category' },
                  props: { label: 'name', value: 'id' }
                }
              }
            }
          },
          ADVISOR_NAME: {
            header: {
              filter: {
                type: 'select',
                field: 'advisorId',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/user',
                    params: (params, value) => (params.payload = { name: value, includingDestroyed: true })
                  },
                  props: { label: 'name', value: 'id' },
                  remote: true
                }
              }
            }
          },
          RECEIPT_PREPARED_DATETIME: {
            header: {
              filter: {
                type: 'date',
                field: ['startDate', 'endDate'],
                props: { type: 'daterange' }
              }
            }
          },
          INVOICE_NO: {
            header: {
              filter: {
                type: 'text',
                field: 'receivable.invoiceNo'
              }
            }
          },
          IS_INVOICE: {
            header: {
              filter: {
                type: 'select',
                field: 'receivable.invoiced',
                props: {
                  options: [
                    { message: '已开票', code: 'Y' },
                    { message: '未开票', code: 'N' }
                  ],
                  props: { label: 'message', value: 'id' }
                }
              }
            }
          },
          PAYMENT_METHOD: {
            header: {
              filter: {
                type: 'select',
                field: 'paymentMethod',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/hint/:hintTypeStr',
                    params: (params) => {
                      params.paths = ['PAMTMTD']
                    }
                  },
                  allowCreate: true,
                  props: { label: 'name', value: 'name' }
                }
              }
            }
          },
          RECEIPT_TYPE: {
            header: {
              filter: {
                type: 'select',
                field: 'paidType',
                props: {
                  ajax: {
                    action: 'GET /enospray/common/lookup/:lookupType',
                    params(params) {
                      params.paths = ['PADTYPE']
                    }
                  },
                  props: { label: 'message', value: 'code' }
                }
              }
            }
          },
          COMMENT: {
            header: {
              filter: {
                type: 'text',
                field: 'serviceComment'
              }
            }
          }
        }
      },
      sale: {
        ajax: {
          get: {
            action: 'GET /enocloud/sale/receipt/query',
            summary: 'GET /enocloud/sale/receipt/summary',
            data: 'array',
            loading: true,
            pagination: true
          }
        },
        summaryMethod<T extends EnocloudServiceDefinitions['ServiceMaintenanceDto'], K extends keyof T>(ctx: {
          columns: TableColumnCtx<T>[]
          data: T[]
        }) {
          return ctx.columns
            .map((item) => item.property)
            .map((key, index) => {
              if (['receiptAmount'].includes(key)) {
                return `￥${ctx.data.reduce((total, d) => calculator.add(total, d[key as K] as number), 0)}`
              } else return !index ? '合计' : '-'
            })
        },
        config: {
          SALE_SERIAL_NO: {
            header: {
              filter: {
                type: 'text',
                field: 'documentSerialNo'
              }
            }
          },
          PAYER_NAME: {
            header: {
              filter: {
                type: 'text',
                field: 'payerName'
              }
            }
          },
          RECEIPT_SERIAL_NO: {
            header: {
              filter: {
                type: 'text',
                field: 'receiptSerialNo'
              }
            }
          },
          RECEIPT_DATETIME: {
            header: {
              filter: {
                type: 'date',
                field: ['startDate', 'endDate'],
                props: { type: 'daterange' }
              }
            }
          },
          PAID_TYPE: {
            header: {
              filter: {
                type: 'select',
                field: 'paidType',
                props: {
                  ajax: {
                    action: 'GET /enospray/common/lookup/:lookupType',
                    params(params) {
                      params.paths = ['PADTYPE']
                    }
                  },
                  props: { label: 'message', value: 'code' }
                }
              }
            }
          }
        }
      }
    }
  },

  mounted() {
    this.order.get()
    this.sale.get()
  }
})
</script>
